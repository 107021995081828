<template>
  <div class="nft-card-list">
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ データが有る場合 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- data は props: {...}の中。親コンポーネントから取得。-->
    <div v-if="data && data.length > 0">
      <div
        class="grid-container grid xl:grid-cols-3
                md:grid-cols-2 grid-cols-1 gap-7 w-full"
      >
        <div
          class="justify-self-center z-30 w-full"
          v-for="card in data"
          :key="`nft-card-list-${card.id}`"
        >
          <CardItem :data="card"></CardItem>
        </div>
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ ページ表示 ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- total は computed: {...mapState({...}) の中。 -->
      <!-- pageSize は data() { return {...}) の中。PICTURE_PER_PAGE。 -->
      <!-- currentPage は data() { return {...}) の中。-->
      <!--                methods: onChangeで変化。    -->
      <Pagination
          class="text-center mt-24"
          :total="total"
          :pageSize="pageSize"
          :current="currentPage"
          @onChange="onChange"
          :showQuickJumper="false"
      ></Pagination>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ ページ表示 ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ データが有る場合 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ データが無い場合 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div v-else>
        <!-- 親コンポーネントでメッセージが指定されている場合。 -->
        <div v-if="this.message!=''" class="text-xl text-black">
            &emsp; &emsp; {{ this.message }}
        </div>
        <!-- 親コンポーネントでメッセージが指定されていない場合。 -->
        <div v-else>
            <a-empty
                class="mt-32 "
            ></a-empty>
        </div>
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ データが無い場合 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- <div class="text-center mt-8">
      <a
        target="_blank"
        href="https://forms.gle/tPJ1NLKTc1tyPozZ6"
        class="font-medium border border-white whitespace-nowrap
                xxs:text-3xl text-2xl bg-transparent rounded-lg
                xxs:px-16 px-4 py-2 text-white h-18"
        >Apply for publication</a
      >
    </div> -->
  </div>
</template>
<!-- =====+=========+=========+=========+=========+=========+=========+===== -->
<script>
import { PICTURE_PER_PAGE } from '@/constants/common.constant';
import Pagination from '@/components/common/Pagination.vue';
import CardItem from '@/components/nft/CardItem/index.vue';
import { mapState } from 'vuex';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'NFTCardList',
  components: {
    CardItem,
    Pagination,
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  props: {
    // NFTのデータ
    data: {
      type: Array,
      default: () => [],
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // NFTデータが無い場合のメッセージ。指定しなければ a-empty タグが使われる。
    message: {
      type: String,
      default: '',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // テーブル categories から抽出した、当該シリーズ配下の全エディションの
    // レコードの数。
    //numberOfCard: {
    //  type: Number,
    //},
  }, // End of props: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      pageSize: PICTURE_PER_PAGE,
      currentPage: 1,
    };
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapState({
      total: (state) => state.marketplace.totalItems || 0,
    }),
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    onChange(page) {
      this.currentPage = page;
      this.$emit('onPageChange', page);
    },
  }, // End of  methods: {...}
}; // End of export default {...}
</script>
<!-- =====+=========+=========+=========+=========+=========+=========+===== -->
<style>
/* グリッドコンテナのスタイリング */
.grid-container {
  background-color: white;
}
</style>
